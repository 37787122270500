:root {
  --monospace: Menlo, monospace;
  --sans-serif: -apple-system, BlinkMacSystemFont, sans-serif;
}

body {
  font-family: var(--sans-serif);
  color: rgb(34, 39, 39);
}

.error {
  color: red;
  background-color: pink;
  border: 0.2em solid currentColor;
  padding: 1em;
  margin: 1em;
  font-family: var(--monospace);
}

code,
pre {
  font-family: var(--monospace);
}

svg {
  width: 1em;
  height: 1em;
}

.inaccessible-link {
  color: currentColor;
}

.inaccessible-button {
  font-size: 1em;
  height: 1em;
  background: none;
  border: none;
  padding: 0;
}

.inaccessible-button:active {
  color: currentColor;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

progress[value] {
  appearance: none;
  width: 100%;
  height: 0.5em;
}

progress[value]::-webkit-progress-bar {
  background: none;
}

progress[value]::-webkit-progress-inner-element {
  border: 1px solid currentColor;
}

progress[value]::-webkit-progress-value {
  background-color: currentColor;
}

button {
  color: currentColor;
}
